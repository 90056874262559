html, body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  overflow-x: hidden;

}

/* START HOME */
.templatemo-home {
  background: url(./components/image/home-bg.jpg);
  background-size: cover;
  background-position: center;
  color: #5f5f5f;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;  
  height: 100vh;
  opacity: 0.9;

}
.tm-home-title,
 .tm-home-subtitle {
  text-transform: uppercase;
 }
.tm-home-title { font-size: 60px; }
.tm-home-subtitle {
  color: #eb5424;
  font-weight: bold;
  padding-bottom: 10px;
  letter-spacing: 2px;
}
.tm-view-more-btn {
  background: #eb5424;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  font-size: 20px;
  font-weight: 700;
  margin-top: 30px;
  padding: 15px 50px;
  -webkit-transition: all 0.6s ease-in;
          transition: all 0.6s ease-in;
    
}
.tm-view-more-btn:hover {
  background: #ffffff;
  color: #eb5424;
}
/* END HOME */





.navbar {
  background:	#202027;
  height: 80px;
  font-size: .9rem;
  
}

.navbar-logo {

  margin-left: 20px;
  cursor: pointer;
  height: 50px;
}


.nav-menu {
  display: grid;
  grid-template-columns: repeat(6, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: end;
  margin-right: 2rem;
  margin-bottom: 0;
}

.nav-item {
  display: flex;
  align-items: center;

}

.nav-links {
  text-decoration: none;
  padding: 0.5rem .5rem;
  color: 	#fff;
}

.nav-links:hover {
  color: 	 #eb5424;
  border-radius: 4px;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 850px) {

  .nav-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    color: #fff;
    padding: 0;
    margin:0;
  }

  .nav-menu.active {
    background:#0a192f;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-item {
    height: 200px;
  }

  .nav-links {
    text-align: center;
    padding: .5rem;
    width: 100%;
    display: table;
    color: #fff;
  }

  .nav-links:hover {
    color:  #eb5424;
    border-radius: 0;
  }

  .menu-icon {
    display: block;
    margin-right: 20px;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    background:  #eb5424;
    text-decoration: none;
    color: #fff;
    font-size: 1.5rem;
  }

  .nav-links-mobile:hover {
    background: #fff;
   
    color: #fff;
    transition: 250ms;
  }

  button {
    display: none;
  }


  .tm-home-title {
    font-size: 3rem;
  }

  .tm-home-subtitle {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 320px) {
  .tm-home-title {
      font-size: 2rem;
    }
  
    .tm-home-subtitle {
      font-size: 1.3rem;
    }
}


/* Homepage Button */
.btn1 {
  --color: #eb5424;
  font-family: inherit;
  display: inline-block;
  width: 8em;
  height: 2.6em;
  line-height: 2.5em;
  margin: 20px;
  position: relative;
  overflow: hidden;
  border: 2px solid var(--color);
  transition: color .5s;
  z-index: 1;
  font-size: 17px;
  border-radius: 6px;
  font-weight: 500;
  color: var(--color);
  text-decoration: none;
 }
 
 .btn1:before {
  content: "";
  position: absolute;
  z-index: -1;
  background: var(--color);
  height: 150px;
  width: 200px;
  border-radius: 50%;
 }
 
 .btn1:hover {
  color: #fff;
 }
 
 .btn1:before {
  top: 100%;
  left: 100%;
  transition: all .7s;
 }
 
 .btn1:hover:before {
  top: -30px;
  left: -30px;
 }
 
 /* resume */

 /* .downloadcv {
  border: 2px solid  #eb5424;
 } */

 /* Contact */
